export class EmailInscri {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public msg?: string,
    public typeClient?: string,
    public emailInscriGeo?: EmailInscriGeo,
  ) {
  }
}

export class EmailInscriGeo {
  constructor(
    public adresseIp?: string,
    public country?: string,
    public regionName?: string,
    public city?: string,
    public zip?: string,
    public lat?: string,
    public lon?: string,
    public timezone?: string,
    public currency?: string,
    public languages?: string,
    public as?: string,
    public org?: string,
  ) {
  }
}
