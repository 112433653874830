import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeARComponent} from './home-ar/home-ar.component';
import {HomeENComponent} from './home-en/home-en.component';


const routes: Routes = [
  {path: '', redirectTo: 'home-en', pathMatch: 'full'},
  {path: 'home-ar', component: HomeARComponent},
  {path: 'home-en', component: HomeENComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
    // other imports here
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
