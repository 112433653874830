import {Component, OnInit} from '@angular/core';
import {IpServiceService} from '../shared/IpServiceService';
import {EmailInscri, EmailInscriGeo} from '../shared/email.model';
import {EmailService} from '../shared/email.service';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-home-en',
  templateUrl: './home-en.component.html',
  styleUrls: ['./home-en.component.css']
})
export class HomeENComponent implements OnInit {

  email: EmailInscri = new EmailInscri();
  contactUs: any = false;
  successSend: any = false;
  successInscSend: any = false;
  editFormInscAttempt: any = false;
  editFormContactAttempt: any = false;
  errorSend: any = false;
  errorInscSend: any = false;

  editFormInsc = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    typeClient: ['', [Validators.required]]
  });

  editFormContact = this.fb.group({
    firstName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    msg: [null, [Validators.required]],
    typeClient: ['', [Validators.required]]
  });

  constructor(public ipServiceService: IpServiceService,
              private fb: FormBuilder,
              public emailService: EmailService) {
  }

  ngOnInit(): void {
    this.ipServiceService.getIPAddress().subscribe((value: any) => {
      this.ipServiceService.getGeoAddress(value.ip).subscribe((geo: any) => {
        this.email.emailInscriGeo = new EmailInscriGeo();
        this.email.emailInscriGeo.adresseIp = geo.ip;
        this.email.emailInscriGeo.country = geo.country_name;
        this.email.emailInscriGeo.city = geo.city;
        this.email.emailInscriGeo.regionName = geo.region;
        this.email.emailInscriGeo.zip = geo.postal;
        this.email.emailInscriGeo.lat = geo.latitude;
        this.email.emailInscriGeo.lon = geo.longitude;
        this.email.emailInscriGeo.timezone = geo.timezone;
        this.email.emailInscriGeo.currency = geo.currency;
        this.email.emailInscriGeo.languages = geo.languages;
        this.email.emailInscriGeo.as = geo.asn;
        this.email.emailInscriGeo.org = geo.org;
      });
    });
  }

  sendEamil() {
    this.editFormContactAttempt = true;
    if (this.editFormContact.valid) {
      this.editFormContactAttempt = false;
      const emailContact = this.createFromContact();
      this.email.email = emailContact.email;
      this.email.typeClient = emailContact.typeClient;
      this.email.msg = emailContact.msg;
      this.email.firstName = emailContact.firstName;
      this.emailService.create(this.email).subscribe((value) => {
        this.successSend = true;
        setTimeout(() => {
          this.successSend = false;
        }, 22000);
        this.editFormContact.reset();
      }, (error) => {
        this.errorSend = true;
        setTimeout(() => {
          this.errorSend = false;
        }, 22000);
      });
    }

  }

  sendEamilInsc() {
    this.editFormInscAttempt = true;

    if (this.editFormInsc.valid) {
      this.editFormInscAttempt = false;
      const emailInscri = this.createFromInsc();
      this.email.email = emailInscri.email;
      this.email.typeClient = emailInscri.typeClient;
      this.emailService.create(this.email).subscribe((value) => {
        this.successInscSend = true;
        setTimeout(() => {
          this.successInscSend = false;
        }, 22000);
        this.editFormInsc.reset();
      }, (error) => {
        this.errorInscSend = true;
        setTimeout(() => {
          this.errorInscSend = false;
        }, 22000);
      });
    }

  }

  displayContactUs() {
    this.contactUs = true;
  }

  closeContactUs() {
    setTimeout(() => {
      this.contactUs = false;
    }, 500);
  }

  private createFromInsc(): EmailInscri {
    return {
      ...new EmailInscri(),
      email: this.editFormInsc.get(['email']).value,
      typeClient: this.editFormInsc.get(['typeClient']).value
    };
  }

  private createFromContact(): EmailInscri {
    return {
      ...new EmailInscri(),
      firstName: this.editFormContact.get(['firstName']).value,
      email: this.editFormContact.get(['email']).value,
      msg: this.editFormContact.get(['msg']).value,
      typeClient: this.editFormContact.get(['typeClient']).value
    };
  }
}
