import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

type EntityResponseType = HttpResponse<any>;

@Injectable(
  {
    providedIn: 'root'
  }
)
export class EmailService {
  private resourceUrl = '/dealsetting/api/email-subscription';

  constructor(private http: HttpClient) {
  }

  create(email: any): Observable<EntityResponseType> {
    return this.http.post(this.resourceUrl, email, {observe: 'response'});
  }
}
